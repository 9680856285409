import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CommercesWrite } from '@/data/commerce/commerce.graphql';
import { InvoiceWrite } from '@/data/commerce/invoice.graphql';
import idPick from '@/helpers/idPick';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import SyncToClover from '@/helpers/syncToClover';
import { getHATotalFromPayments } from '@/pages/dashboard/commerce/components/sharedActionUtils';
import { getClientForMerchant } from '@/pages/tender/[id]/drawersActions/clientHelpers';
import {
	Client,
	HouseAccount,
	Location,
	MutationCommercesWriteArgs,
	MutationHouseAccountWriteArgs,
	MutationInvoiceWriteArgs,
	Order,
	QueryHouseAccountReadArgs,
	Staff,
} from '@/types/schema';
import postCloverMeteredBilling from '@/utils/api/postCloverMeteredBilling';
import { getBrowserTimezone } from '@/utils/timezone';
import { gql } from '@apollo/client';
import { set, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { isEmpty, isString, sortBy, toLower } from 'lodash-es';
import { validate } from 'uuid';

export async function createHouseAccountFromClient( input: Partial<HouseAccount>, staff: Staff, clientId?: string ) {
	return await mutateGraphQL<MutationHouseAccountWriteArgs>( {
		mutation : HAGenerateWrite,
		variables: {
			method: 'New House Account',
			input : {
				name   : input?.name || 'New House Account',
				email  : input?.email ? toLower( input.email ) : null,
				contact: input.contact,
				phone  : input?.phone || null,
				active : true,
				client : clientId || null,
				staff  : staff?.id || null,
			},
		},
	} );
}

export async function generateHAInvoice(
	invoices: Order[],
	staff: Staff,
	houseAccount: HouseAccount | undefined,
	month?: string,
	client?: Client | null,
	location?: Location,
	skipBilling?: true ) {
	if ( isEmpty( invoices ) ) {
		throw new Error( 'No invoices available.' );
	}
	
	const timezone = getBrowserTimezone();
	let invoiceHouseAccount: HouseAccount | undefined = houseAccount;
	// if deleted HA, then create a copy
	if ( invoiceHouseAccount?.id ) {
		try {
			const { houseAccountRead } = await queryGraphQL<QueryHouseAccountReadArgs>( {
				query: gql`
					query HouseAccountRead_6ea1($id: String) {
						houseAccountRead(id: $id) {
							id
						}
					}
				`,
				variables: { id: invoiceHouseAccount.id },
			} );
			invoiceHouseAccount = houseAccountRead;
		} catch {
			const input = {
				name   : houseAccount?.name,
				contact: houseAccount?.contact,
				email  : houseAccount?.email,
				phone  : houseAccount?.phone,
			};
			
			const { houseAccountWrite } = await createHouseAccountFromClient( input, staff, client?.id );
			invoiceHouseAccount = houseAccountWrite;
		}
	} else {
		if ( client ) {
			const input = { name: client.name, contact: client.contact, email: client?.email, phone: client?.phone };
			
			const { houseAccountWrite } = await createHouseAccountFromClient( input, staff, client?.id );
			invoiceHouseAccount = houseAccountWrite;
		}
	}
	
	invoices = sortBy( invoices, [ 'createdAt' ] );
	const invoiceLineItems = invoices.map( ( invoice, index ) => {
		const desc = safeFormatInTimeZone( invoice.serviceDate || invoice.updatedAt || new Date(), 'PP' );
		
		let price: number = 0;
		if ( invoice.status !== 'PAID' && !isEmpty( invoice.payments ) ) {
			const paymentAmount = getHATotalFromPayments( invoice );
			price += paymentAmount.paidAmount - paymentAmount.refundedAmount;
		} else {
			if ( invoice.status === 'PAID' ) {
				price = isEmpty( invoice.payments ) ? invoice.paidTotal || 0 : 0;
			} else {
				price += invoice.grandTotal - ( invoice.paidTotal || 0 );
			}
		}
		
		return {
			id  : undefined,
			name: `#${invoice.externalId || invoice.number} ${invoice.metadata?.customNumber
				? `(${invoice.metadata.customNumber})`
				: ''}`,
			tax        : 0,
			sequence   : index,
			price      : price,
			quantity   : 1,
			unit       : 'Order',
			description: `${desc} ${invoice.po ? `PO: ${invoice.po}` : ''} ${invoice.notes
				? `Note: ${invoice.notes}`
				: ''}`,
		};
		
	} );
	
	let merchantClient: Client | null | undefined = client;
	if ( client && location ) {
		merchantClient = await getClientForMerchant( {
			companyLocation: location,
		}, client, staff );
	}
	
	let selectedMonth: Date | null = null;
	if ( month ) {
		const date = new Date();
		selectedMonth = utcToZonedTime( set( subDays( new Date( month ), 1 ), {
			hours  : date.getHours(),
			minutes: date.getMinutes(),
		} ), timezone );
	}
	
	const { invoiceWrite } = await mutateGraphQL<MutationInvoiceWriteArgs>( {
		mutation : InvoiceWrite,
		variables: {
			id          : undefined,
			customNumber: Boolean( staff.company.metadata?.customNumber ),
			method      : 'Account Invoice Created',
			input       : {
				client         : merchantClient?.id || null,
				staff          : staff?.id || null,
				companyLocation: location?.id || null,
				serviceDate    : selectedMonth ? selectedMonth : invoices[ 0 ].serviceDate || invoices[ 0 ].updatedAt,
				standingDate   : new Date(),
				metadata       : {
					enableCashDiscount: staff?.company?.metadata?.cashDiscount > 0,
					cardInfo          : {},
					mergedTo          : null,
					signatureLine     : staff.company.metadata?.signatureLine,
					sendReminder      : staff.company.metadata?.dueReminder,
					accountInvoiceDate: selectedMonth ? selectedMonth : invoices[ 0 ].serviceDate || invoices[ 0 ].updatedAt,
					documentLayout    : {
						color: {
							dark : '#424074',
							light: '#b1adf4',
						},
						template      : 'default',
						lineItemLayout: 'industrial',
					},
				},
				type        : 'ACCOUNT',
				houseAccount: invoiceHouseAccount?.id || houseAccount?.id || null,
				lineItems   : invoiceLineItems.map( ( lineItem ) => ( {
					...idPick( lineItem, [
						'name',
						'price',
						'quantity',
						'tax',
						'unit',
						'sequence',
					] ),
					description: lineItem.description?.trim(),
					isRevenue  : false,
				} ) ),
			},
		},
	} );
	
	mutateGraphQL<MutationCommercesWriteArgs>( {
		mutation : CommercesWrite,
		variables: {
			inputs: invoices.map( ( commerce ) => ( {
				id      : validate( commerce.id ) ? commerce.id : undefined,
				metadata: { mergedTo: invoiceWrite.id },
			} ) ),
		},
	} ).catch( () => null );
	
	if ( !skipBilling && location?.gateway || invoiceWrite.companyLocation?.gateway ) {
		await postCloverMeteredBilling( {
			orderId    : invoiceWrite.id,
			gatewayId  : location?.gateway?.id || invoiceWrite.companyLocation?.gateway?.id,
			eventType  : 'HA Statement',
			key        : 'HAOneBillStatement',
			staffId    : staff?.id,
			clientId   : invoiceWrite.client?.id || undefined,
			entityCount: invoices.length || 1,
		} ).catch( () => null );
	}
	
	let newCommerce = invoiceWrite;
	
	if ( invoiceWrite.companyLocation?.gateway ) {
		
		try {
			const cloverCommerce = await SyncToClover( invoiceWrite );
			newCommerce = cloverCommerce?.commerce;
		} catch ( e ) {
			const cloverErrors = e?.response.data?.cloverErrors;
			if ( cloverErrors ) {
				throw isString( cloverErrors )
					? cloverErrors
					: cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred - Clover.com';
			} else {
				throw new Error( 'A sync conflict occurred, try to sync again - Clover.com' );
			}
		}
		
	}
	
	return newCommerce || invoiceWrite;
}

export const AccountInvoicesRead = gql`
	query InvoicesRead_fe8e($options: FilterOptions) {
		invoicesRead(options: $options) {
			items {
				id
			}
			count
		}
	}
`;

export const HAGenerateWrite = gql`
	mutation HouseAccountWrite_ba8b($id: String,$method: String,  $input: HouseAccountValidator,$remove: Boolean) {
		houseAccountWrite(id: $id,   method: $method,input: $input, remove: $remove) {
			id
		}
	}
`;

export const HAInvoiceDuplicatesWrite = gql`
	mutation InvoiceDuplicate_df90($ids: [String!]!,$method: String,) {
		invoiceDuplicate(ids: $ids, method: $method) {
			id
			metadata
			company {
				id
			}
			companyLocation {
				gateway {
					id
				}
			}
			client {
				id
				name
				email
				contact
				cell
				statement
				metadata
				logo
				addresses {
					line1
					line2
					city
					country
					state
					postalCode
					lat
					lng
				}
				gateway {
					externalId
				}
			}
		}
	}
`;

export const HAInvoiceDuplicateWrite = gql`
	mutation InvoiceDuplicate_c4bb($ids: [String!]!,$method: String,) {
		invoiceDuplicate(ids: $ids, method: $method) {
			id
			metadata
			client {
				name
				email
				contact
				cell
				statement
				metadata
				logo
				addresses {
					line1
					line2
					city
					country
					state
					postalCode
					lat
					lng
				}
				gateway {
					externalId
				}
			}
		}
	}
`;

export const EmployeeCommerces = gql`query ClientCommerces($options: FilterOptions) {
	clientCommerces(options: $options) {
		items {
			id
			metadata
			number
			externalId
			type
			grandTotal
		}
		count
	}
}`;
